import { defineStore } from 'pinia'
import { isAxiosError } from 'axios'
import type { AxiosResponse } from 'axios'

let omnicartRefreshTimeout: ReturnType<typeof setTimeout> | null = null
let omnicartRefreshable = false
let abortController: AbortController | null = null

export const useOmnicartStore = defineStore('omnicart', {
  state: () => ({
    cartItems: [] as OmnicartResponseItem[],
    loading: false,
    error: null as ApiFormError | null,
  }),
  getters: {
    itemsCount: (state) => state.cartItems.length,
    itemsAsMinimalShipment: (state) => {
      const getItemQuote = (item: OmnicartResponseItem): QuoteResponse | null => {
        if (item.data.serviceId === null || item.rates === null || item.rates.length === 0) {
          return null
        }
        return item.rates.find((quote) => quote.selectable === true) || null
      }
      const getPickupTypeFromQuote = (item: OmnicartResponseItem): string | null => {
        const quote = getItemQuote(item)
        if (quote === null) {
          return null
        }
        if (quote.collectionType === 'OCCASIONAL') {
          return 'COLLECTION_PLACE'
        } else if (quote.pickupBranch !== null || quote.pickupBranchLocation !== null) {
          return 'PARCEL_SHOP'
        } else {
          return 'DOOR'
        }
      }
      const getCarrierFromQuote = (item: OmnicartResponseItem): { code: string; name: string } | null => {
        const quote = getItemQuote(item)
        if (quote === null) {
          return null
        }
        return {
          code: quote.carrier.code,
          name: formatCarrierName(quote),
        }
      }
      const getTimeInTransitFromQuote = (
        item: OmnicartResponseItem,
      ): { pickup: string; delivery: string; daysInTransit: number } | null => {
        const quote = getItemQuote(item)
        if (quote === null || quote.timeInTransit.pickupDate === null || quote.timeInTransit.deliveryDate === null) {
          return null
        }
        return {
          pickup: quote.timeInTransit.pickupDate,
          delivery: quote.timeInTransit.deliveryDate,
          daysInTransit: (quote.timeInTransit.hoursInTransit || 0) / 24,
        }
      }
      const getPriceFromQuote = (
        item: OmnicartResponseItem,
      ): { priceWithVat: Money; priceWithoutVat: Money } | null => {
        const quote = getItemQuote(item)
        if (quote === null || quote.priceVat === null || quote.price === null) {
          return null
        }
        return {
          priceWithVat: quote.priceVat,
          priceWithoutVat: quote.price,
        }
      }
      const getCodItemFromServices = (services: OmnicartItemService[]) => {
        const cod = (services || []).find((item) => item.code === 'COD')
        if (cod === undefined) {
          return null
        }
        return {
          amount: cod.data.value,
          status: null,
        }
      }

      return state.cartItems.map(
        (item) =>
          ({
            id: item.id,
            source: item.source,
            pickupType: getPickupTypeFromQuote(item),
            packages: item.data.packages,
            carrier: getCarrierFromQuote(item),
            referenceNumber: item.data.referenceNumber,
            pickupDate: getTimeInTransitFromQuote(item)?.pickup || null,
            pickupDateReal: null,
            deliveryDate: getTimeInTransitFromQuote(item)?.delivery || null,
            deliveryDateReal: null,
            daysInTransit: getTimeInTransitFromQuote(item)?.daysInTransit || null,
            codItem: getCodItemFromServices(item.data.services),
            fromTime: item.data.fromTime || null,
            fromContact: item.data.from || {},
            toContact: item.data.to || {},
            priceWithVat: getPriceFromQuote(item)?.priceWithVat || null,
            priceWithoutVat: getPriceFromQuote(item)?.priceWithoutVat || null,
            modified: item.modified,
            errors: item.errors,
            quotesLoading: item.rates === null,
            statusCode: undefined,
          }) as OmnicartListItem,
      )
    },
    getItemById: (state) => {
      return (id: number) => {
        return state.cartItems.find((item) => item.id === id)
      }
    },
    getItemQuoteRequestById: (state) => {
      return (id: number) => {
        const item = state.cartItems.find((item) => item.id === id)
        if (item === undefined) {
          return null
        }
        const emptyAddress = {
          street: '',
          city: '',
          zip: '',
          country: '',
        }
        const requestData: QuoteRequest = {
          from: item.data.from || emptyAddress,
          to: item.data.to || emptyAddress,
          pickup_branch_location: item.data.pickupBranchLocation,
          delivery_branch_location: item.data.deliveryBranchLocation,
          packages: item.data.packages.map((item) => ({
            weight: Number(item.weight),
            width: Number(item.width),
            height: Number(item.height),
            length: Number(item.length),
            type: item.type,
          })),
          services: [],
          complete: false,
        }
        const codService = (item.data.services || []).find((item) => item.code === 'COD')
        const insuranceService = (item.data.services || []).find((item) => item.code === 'INS')
        const hdfService = (item.data.services || []).find((item) => item.code === 'HDF')
        if (codService) {
          requestData.services.push({
            code: 'COD',
            data: {
              value: {
                value: codService.data.value.value,
                currency: codService.data.value.currency,
              },
            },
          })
        }
        if (insuranceService) {
          requestData.services.push({
            code: 'INS',
            data: {
              value: insuranceService.data.value,
              currency: insuranceService.data.currency,
            },
          })
        }
        if (hdfService) {
          requestData.services.push({
            code: 'HDF',
          })
        }
        return requestData
      }
    },
    getItemsPriceTotals: (state) => {
      return (ids: number[], currency = '') => {
        const config = useRuntimeConfig()
        currency = currency === '' ? config.public.brandConfig.defaultCurrency : currency
        const prices = state.cartItems
          .filter((item) => ids.includes(item.id))
          .map((item) => {
            let quote = null
            if (item.data.serviceId !== null && item.rates !== null && item.rates.length > 0) {
              quote = item.rates.find((quote) => quote.selectable === true) || null
            }
            if (quote === null || quote.priceVat === null || quote.price === null) {
              return {
                priceWithVat: {
                  value: 0,
                  currency,
                },
                priceWithoutVat: {
                  value: 0,
                  currency,
                },
              }
            }
            return {
              priceWithVat: quote.priceVat,
              priceWithoutVat: quote.price,
            }
          })
        const priceWithoutVat: Money = {
          value: 0,
          currency,
        }
        const priceWithVat: Money = {
          value: 0,
          currency,
        }
        prices.forEach((item) => {
          ;(priceWithoutVat.value as number) += Number(item.priceWithoutVat.value)
          ;(priceWithVat.value as number) += Number(item.priceWithVat.value)
          priceWithoutVat.currency = item.priceWithoutVat.currency
          priceWithVat.currency = item.priceWithVat.currency
        })
        return {
          priceWithoutVat,
          priceWithVat,
        }
      }
    },
  },
  actions: {
    async fetchOmnicart() {
      this.loading = true
      this.error = null
      omnicartRefreshable = true
      if (omnicartRefreshTimeout !== null) {
        clearTimeout(omnicartRefreshTimeout)
      }

      const { $api } = useNuxtApp()

      try {
        if (abortController !== null && !abortController.signal.aborted) {
          abortController.abort()
        }

        abortController = new AbortController()

        const { data } = await $api.get('/user/omni-cart/list', {
          signal: abortController.signal,
        })

        this.cartItems = data.list

        const hasItemWithLoadingRates = this.cartItems.some((item) => item.rates === null)
        if (hasItemWithLoadingRates && omnicartRefreshable) {
          omnicartRefreshTimeout = setTimeout(() => {
            this.fetchOmnicartUpdate()
          }, 1000)
        }
      } catch (e: unknown) {
        if (isAxiosError(e) && e.response) {
          const errResponse = e.response as AxiosResponse
          this.error = {
            message: errResponse.data?.message,
            errors: errResponse.data?.errors,
          }
        } else {
          this.error = {
            message: (e as Error).message,
          }
        }
      } finally {
        this.loading = false
        this.error = null
      }
    },
    async fetchOmnicartUpdate() {
      if (omnicartRefreshTimeout !== null) {
        clearTimeout(omnicartRefreshTimeout)
      }

      const { $api } = useNuxtApp()

      try {
        const { data } = await $api.get('/user/omni-cart/list')

        const refreshableCartItems = this.cartItems.filter((item) => item.rates === null)
        refreshableCartItems.forEach((item) => {
          const itemIdx = this.cartItems.findIndex((cItem) => cItem.id === item.id)
          const refreshedItem = (data.list as OmnicartResponseItem[]).find((lItem) => lItem.id === item.id)
          if (refreshedItem !== undefined) {
            this.cartItems[itemIdx] = refreshedItem
          }
        })

        const hasItemWithLoadingRates = this.cartItems.some((item) => item.rates === null)
        if (hasItemWithLoadingRates && omnicartRefreshable) {
          omnicartRefreshTimeout = setTimeout(() => {
            this.fetchOmnicartUpdate()
          }, 1000)
        }
      } catch (e: unknown) {
        console.error(e)
        // TODO: handle
      }
    },
    stopOmnicartUpdate() {
      this.loading = false

      if (omnicartRefreshTimeout !== null) {
        clearTimeout(omnicartRefreshTimeout)
      }
      omnicartRefreshable = false
    },
    async deleteOmnicartItem(id: number) {
      const { $api, $i18n } = useNuxtApp()

      try {
        await $api.delete('/user/omni-cart/delete/' + id)
        this.fetchOmnicart()
      } catch (e: unknown) {
        let error = ''
        if (isAxiosError(e) && e.response) {
          const errResponse = e.response as AxiosResponse
          error = errResponse.data?.message || $i18n.t('stores.omnicart_store.unable_delete', 0)
        } else {
          error = (e as Error).message
        }
        openAlert($i18n.t('stores.omnicart_store.error', 0), error)
      }
    },
    async deleteOmnicartItems(ids: number[]) {
      const { $api, $i18n } = useNuxtApp()

      try {
        this.loading = true
        const promises = ids.map((id) => {
          return $api.delete('/user/omni-cart/delete/' + id)
        })
        await Promise.all(promises)
      } catch (e: unknown) {
        let error = ''
        if (isAxiosError(e) && e.response) {
          const errResponse = e.response as AxiosResponse
          error = errResponse.data?.message || $i18n.t('stores.omnicart_store.unable_delete', 1)
        } else {
          error = (e as Error).message
        }
        openAlert($i18n.t('stores.omnicart_store.error', 1), error)
      } finally {
        this.fetchOmnicart()
      }
    },
  },
  persist: false,
})

import { defineStore } from 'pinia'
import { useAuthStore } from '~/stores/auth-store'

export const useUserStore = defineStore('user', {
  state: () => ({
    profile: null as Profile | null,
    hiddenNewsBoxArticles: [] as string[],
  }),
  getters: {
    profileAddress: (state) => {
      if (state.profile) {
        return {
          id: -1,
          type: 'profile',
          tin: state.profile.tin,
          vatin: state.profile.vatin,
          company: state.profile.company,
          firstname: state.profile.firstname,
          surname: state.profile.surname,
          phone: state.profile.phone,
          email: state.profile.email,
          street: state.profile.street,
          city: state.profile.city,
          zip: state.profile.zip,
          country: state.profile.country,
        } as Address
      }
      return null
    },
    contractType: (state) => {
      if (state.profile) {
        switch (state.profile.price_type) {
          case 3:
            return 'profi'
          case 4:
            return 'flexi'
          case 5:
            return 'easy'
          default:
            return 'retail'
        }
      }
      return 'retail'
    },
    isNewsboxArticleHidden: (state) => {
      return (id: string) => {
        return state.hiddenNewsBoxArticles.includes(id)
      }
    },
  },
  actions: {
    async fetchProfile() {
      if (!useAuthStore().isAuthenticated) {
        return
      }

      const { $apiV1, $api } = useNuxtApp()
      const { data } = await $apiV1.post('/user/profile')
      const dataSetting = await $api.get('/user/settings/list-common')

      this.profile = {
        ...data.data,
        labelFormat: dataSetting.data.labelFormat,
        defaultCollectionPlaces: dataSetting.data.defaultCollectionPlaces.reduce(
          (a, b) => {
            a[b.carrierCode] = b.collectionPlaceId
            return a
          },
          {} as Record<CarrierCode, number | null>,
        ),
        saveFromAddress: dataSetting.data.saveFromAddress,
        saveToAddress: dataSetting.data.saveToAddress,
        clientSectionPreferenceSm: dataSetting.data.clientSectionPreferenceSm,
        orderTypePreferenceSm: dataSetting.data.orderTypePreferenceSm,
        forceSm: dataSetting.data.forceSm,
        defaultPageSm: dataSetting.data.defaultPageSm,
        shipmentsTableDisplayPreferenceSm: dataSetting.data.shipmentsTableDisplayPreferenceSm,
        from_address: data.data.from_address
          ? {
              ...data.data.from_address,
              type: 'default',
            }
          : undefined,
        to_address: data.data.to_address
          ? {
              ...data.data.to_address,
              type: 'default',
            }
          : undefined,
      }
    },
    hideNewsboxArticle(id: string) {
      if (!this.hiddenNewsBoxArticles.includes(id)) {
        this.hiddenNewsBoxArticles.push(id)
      }
    },
  },
  persist: true,
})

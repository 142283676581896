<template>
  <div class="fixed left-0 top-0 z-[1000] size-full bg-black opacity-25" />
  <div class="fixed left-0 top-0 z-[1500] size-full">
    <BasePopover
      ref="popper"
      :show="show"
      :styleless-content="true"
      :placement="placement"
    >
      <div
        ref="anchor"
        class="relative"
        :class="{
          'w-12': placement === 'left' || placement === 'right',
          'w-64': placement === 'top' || placement === 'bottom',
        }"
      />
      <template #content>
        <div class="w-64">
          <div
            class="rounded-t-lg border-b border-gray-200 bg-gray-50 px-3 py-1.5 text-sm font-semibold dark:bg-gray-900"
          >
            {{ title }}
          </div>
          <div class="px-3 py-2 text-sm text-gray-500 dark:text-gray-400">
            {{ content }}
          </div>
          <div class="flex items-center justify-between px-3 pb-2">
            <span
              v-if="!isLastItem"
              class="cursor-pointer text-xs text-gray-400 hover:underline"
              @click="$emit('end')"
            >{{ $t('components.popover_guide.finish') }}</span>
            <span v-else />

            <span
              v-if="!isLastItem"
              class="cursor-pointer text-sm text-primary-600 hover:underline"
              @click="showNextGuideItem"
            >{{ $t('components.popover_guide.next') }}</span>
            <span
              v-else
              class="cursor-pointer text-sm text-primary-600 hover:underline"
              @click="$emit('end')"
            >{{ $t('components.popover_guide.finish') }}</span>
          </div>
        </div>
      </template>
    </BasePopover>
  </div>
</template>

<script lang="ts" setup>
interface GuideItem {
  anchor: string
  placement: string
  title: string
  content: string
  offsetTop?: number
  offsetLeft?: number
}

const { t } = useI18n()
const guideDefinitions: { [k: string]: GuideItem[] } = {
  shipments: [
    {
      anchor: 'shipmentsFilterOmnicart',
      placement: 'bottom',
      title: t('app_guide.shipments.filter_omnicart.title'),
      content: t('app_guide.shipments.filter_omnicart.content'),
    },
    {
      anchor: 'shipmentsFilterAll',
      placement: 'bottom',
      title: t('app_guide.shipments.filter_all.title'),
      content: t('app_guide.shipments.filter_all.content'),
    },
    {
      anchor: 'sidebarItemPickups',
      placement: 'right',
      title: t('app_guide.shipments.pickups.title'),
      content: t('app_guide.shipments.pickups.content'),
    },
    {
      anchor: 'sidebarItemSettings',
      placement: 'right',
      title: t('app_guide.shipments.settings_templates.title'),
      content: t('app_guide.shipments.settings_templates.content'),
      offsetTop: -10,
    },
    {
      anchor: 'sidebarItemSettings',
      placement: 'right',
      title: t('app_guide.shipments.settings_integrations.title'),
      content: t('app_guide.shipments.settings_integrations.content'),
      offsetTop: 10,
    },
  ],
}

const emit = defineEmits<{
  (e: 'end'): void
}>()
const props = withDefaults(
  defineProps<{
    guide?: null | string
  }>(),
  {
    guide: null,
  },
)

const activeGuide = ref<GuideItem[] | null>(null)
const activeIndex = ref(0)
const show = ref(true)
const title = ref('')
const content = ref('')
const placement = ref('bottom')
const anchor = ref(null)

const isLastItem = computed<boolean>(() => {
  return activeGuide.value !== null && activeIndex.value === activeGuide.value.length - 1
})

const popper = ref()
const position = (x: number, y: number) => {
  const el = popper.value.popperRef.$el
  el.style.position = 'relative'
  el.style.height = '0'
  el.style.top = `${y}px`
  el.style.left = `${x}px`
}

const startGuide = async (name: string) => {
  const guide = guideDefinitions[name]
  if (guide) {
    await nextTick()
    activeGuide.value = guide
    activeIndex.value = -1
    position(0, 0)
    showNextGuideItem()
  }
}

const showNextGuideItem = async () => {
  activeIndex.value++
  const item = activeGuide.value[activeIndex.value]
  if (item) {
    const anchorElement = document.querySelector(`[data-guide-anchor="${item.anchor}"]`) as HTMLElement | null
    if (anchorElement !== null) {
      title.value = item.title
      content.value = item.content
      placement.value = item.placement

      await nextTick()

      const bodyWidth = document.body.offsetWidth
      const bodyHeight = document.body.offsetHeight
      const popperRect = popper.value.popperRef.$el.getBoundingClientRect()
      const rect = anchorElement.getBoundingClientRect()
      const offsetTop = item.offsetTop || 0
      const offsetLeft = item.offsetLeft || 0

      const x =
        item.placement === 'top' || item.placement === 'bottom'
          ? offsetLeft + rect.x - (popperRect.width - rect.width) / 2
          : offsetLeft + rect.x
      const y =
        item.placement === 'top' || item.placement === 'bottom'
          ? offsetTop + rect.y
          : offsetTop + rect.y - (popperRect.height - rect.height) / 2

      if (x + popperRect.width <= bodyWidth && y + popperRect.height <= bodyHeight) {
        position(x, y)
        show.value = true
      } else {
        if (isLastItem.value) {
          emit('end')
        } else {
          showNextGuideItem()
        }
      }
    }
  }
}

const { guide } = toRefs(props)
watch(
  guide,
  (val) => {
    if (val !== null) {
      startGuide(val)
    }
  },
  { immediate: true },
)
</script>

import { setLocale } from 'yup'

const paths: Record<string, string> = {
  password: 'Heslo',
}

export default defineNuxtPlugin(() => {
  const { $i18n } = useNuxtApp()

  setLocale({
    // use constant translation keys for messages without values
    mixed: {
      default: $i18n.t('validation.default'),
      required: $i18n.t('validation.required'),
      notType: ({ type }) => {
        const typeNames = {
          number: $i18n.t('validation.field_type_number'),
          string: $i18n.t('validation.field_type_string'),
          date: $i18n.t('validation.field_type_date'),
        } as { [k: string]: string }
        return $i18n.t('validation.not_type', {
          type: typeNames[type] ? typeNames[type] : $i18n.t('validation.field_type_default'),
        })
      },
    },
    // use functions to generate an error object that includes the value from the schema
    string: {
      min: ({ min, path }) =>
        $i18n.t('validation.min_length', { field: paths[path] || $i18n.t('validation.default_field'), n: min }),
      email: () => $i18n.t('validation.email'),
    },
    number: {
      min: ({ min }) => {
        return $i18n.t('validation.min', { n: min })
      },
      max: ({ max }) => {
        return $i18n.t('validation.max', { n: max })
      },
      integer: $i18n.t('validation.integer'),
    },
  })
})

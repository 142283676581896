import { default as _91id_93WAhLb6eE3kMeta } from "/builds/zaslat/zaslat-b2x/pages/address-book/[id].vue?macro=true";
import { default as address_45bookJ2zszvASgNMeta } from "/builds/zaslat/zaslat-b2x/pages/address-book.vue?macro=true";
import { default as claims5k1RdedmNtMeta } from "/builds/zaslat/zaslat-b2x/pages/claims.vue?macro=true";
import { default as indexTe388fzbkuMeta } from "/builds/zaslat/zaslat-b2x/pages/index.vue?macro=true";
import { default as invoicingNGZyXIiFvxMeta } from "/builds/zaslat/zaslat-b2x/pages/invoicing.vue?macro=true";
import { default as _91token_93xnxp07vkGPMeta } from "/builds/zaslat/zaslat-b2x/pages/login/[token].vue?macro=true";
import { default as indexHkBXyNixPPMeta } from "/builds/zaslat/zaslat-b2x/pages/login/index.vue?macro=true";
import { default as logoutW2kEyidEjfMeta } from "/builds/zaslat/zaslat-b2x/pages/logout.vue?macro=true";
import { default as lost_45passwordP2xZGzxbmiMeta } from "/builds/zaslat/zaslat-b2x/pages/lost-password.vue?macro=true";
import { default as _91id_93vEtN7Bj2pBMeta } from "/builds/zaslat/zaslat-b2x/pages/pickups/addresses/[id].vue?macro=true";
import { default as addressesY0oghcI76XMeta } from "/builds/zaslat/zaslat-b2x/pages/pickups/addresses.vue?macro=true";
import { default as planWBGqWKxL8mMeta } from "/builds/zaslat/zaslat-b2x/pages/pickups/plan.vue?macro=true";
import { default as _91_46_46_46slug_93ySULSbfi9WMeta } from "/builds/zaslat/zaslat-b2x/pages/register/[...slug].vue?macro=true";
import { default as _91token_93tx3yjoZnN2Meta } from "/builds/zaslat/zaslat-b2x/pages/reset-password/[token].vue?macro=true";
import { default as accountiRGizeuYWgMeta } from "/builds/zaslat/zaslat-b2x/pages/settings/account.vue?macro=true";
import { default as commonfjbp7IqRZcMeta } from "/builds/zaslat/zaslat-b2x/pages/settings/common.vue?macro=true";
import { default as creditoYsNCUbXUcMeta } from "/builds/zaslat/zaslat-b2x/pages/settings/credit.vue?macro=true";
import { default as indexiSsfbPXyFfMeta } from "/builds/zaslat/zaslat-b2x/pages/settings/index.vue?macro=true";
import { default as integrations8FVyQGbktDMeta } from "/builds/zaslat/zaslat-b2x/pages/settings/integrations.vue?macro=true";
import { default as notificationsFmK1eJExnoMeta } from "/builds/zaslat/zaslat-b2x/pages/settings/notifications.vue?macro=true";
import { default as pricelistkkDPfKRlwzMeta } from "/builds/zaslat/zaslat-b2x/pages/settings/pricelist.vue?macro=true";
import { default as indexxzde6PzjL8Meta } from "/builds/zaslat/zaslat-b2x/pages/settings/templates/index.vue?macro=true";
import { default as package_45customs1cLCKYg0UwMeta } from "/builds/zaslat/zaslat-b2x/pages/settings/templates/package-customs.vue?macro=true";
import { default as packageIGPgK7Zh1xMeta } from "/builds/zaslat/zaslat-b2x/pages/settings/templates/package.vue?macro=true";
import { default as pickup_45branchbAY0aP4SkdMeta } from "/builds/zaslat/zaslat-b2x/pages/settings/templates/pickup-branch.vue?macro=true";
import { default as shipmentsfvcE2Iki94Meta } from "/builds/zaslat/zaslat-b2x/pages/shipments.vue?macro=true";
import { default as styleguideYwS5o4QC8IMeta } from "/builds/zaslat/zaslat-b2x/pages/styleguide.vue?macro=true";
export default [
  {
    name: "address-book",
    path: "/address-book",
    meta: address_45bookJ2zszvASgNMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/address-book.vue").then(m => m.default || m),
    children: [
  {
    name: "address-book-id",
    path: ":id()",
    component: () => import("/builds/zaslat/zaslat-b2x/pages/address-book/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "claims",
    path: "/claims",
    meta: claims5k1RdedmNtMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/claims.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexTe388fzbkuMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invoicing",
    path: "/invoicing",
    meta: invoicingNGZyXIiFvxMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/invoicing.vue").then(m => m.default || m)
  },
  {
    name: "login-token",
    path: "/login/:token()",
    meta: _91token_93xnxp07vkGPMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/login/[token].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexHkBXyNixPPMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutW2kEyidEjfMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "lost-password",
    path: "/lost-password",
    meta: lost_45passwordP2xZGzxbmiMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/lost-password.vue").then(m => m.default || m)
  },
  {
    name: "pickups-addresses",
    path: "/pickups/addresses",
    meta: addressesY0oghcI76XMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/pickups/addresses.vue").then(m => m.default || m),
    children: [
  {
    name: "pickups-addresses-id",
    path: ":id()",
    component: () => import("/builds/zaslat/zaslat-b2x/pages/pickups/addresses/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "pickups-plan",
    path: "/pickups/plan",
    meta: planWBGqWKxL8mMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/pickups/plan.vue").then(m => m.default || m)
  },
  {
    name: "register-slug",
    path: "/register/:slug(.*)*",
    meta: _91_46_46_46slug_93ySULSbfi9WMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/register/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "reset-password-token",
    path: "/reset-password/:token()",
    meta: _91token_93tx3yjoZnN2Meta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "/settings/account",
    meta: accountiRGizeuYWgMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-common",
    path: "/settings/common",
    meta: commonfjbp7IqRZcMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/settings/common.vue").then(m => m.default || m)
  },
  {
    name: "settings-credit",
    path: "/settings/credit",
    meta: creditoYsNCUbXUcMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/settings/credit.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexiSsfbPXyFfMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-integrations",
    path: "/settings/integrations",
    meta: integrations8FVyQGbktDMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "/settings/notifications",
    meta: notificationsFmK1eJExnoMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: "settings-pricelist",
    path: "/settings/pricelist",
    meta: pricelistkkDPfKRlwzMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/settings/pricelist.vue").then(m => m.default || m)
  },
  {
    name: "settings-templates",
    path: "/settings/templates",
    meta: indexxzde6PzjL8Meta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/settings/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-templates-package-customs",
    path: "/settings/templates/package-customs",
    meta: package_45customs1cLCKYg0UwMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/settings/templates/package-customs.vue").then(m => m.default || m)
  },
  {
    name: "settings-templates-package",
    path: "/settings/templates/package",
    meta: packageIGPgK7Zh1xMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/settings/templates/package.vue").then(m => m.default || m)
  },
  {
    name: "settings-templates-pickup-branch",
    path: "/settings/templates/pickup-branch",
    meta: pickup_45branchbAY0aP4SkdMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/settings/templates/pickup-branch.vue").then(m => m.default || m)
  },
  {
    name: "shipments",
    path: "/shipments",
    meta: shipmentsfvcE2Iki94Meta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/shipments.vue").then(m => m.default || m)
  },
  {
    name: "styleguide",
    path: "/styleguide",
    meta: styleguideYwS5o4QC8IMeta || {},
    component: () => import("/builds/zaslat/zaslat-b2x/pages/styleguide.vue").then(m => m.default || m)
  }
]
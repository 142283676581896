import { VueReCaptcha } from 'vue-recaptcha-v3'

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(VueReCaptcha, {
    siteKey: '6LdQfycbAAAAAJ_SLSmHtooUJOdCb1DfUtl2XuRl',
    loaderOptions: {
      autoHideBadge: true,
    },
  })
})

interface I18NMsgObject {
  children: string
}

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.client && localStorage.getItem('zsltWeblateActive') === '1') {
    nuxtApp.$i18n.setPostTranslationHandler((msg: string | I18NMsgObject[], key: string) => {
      if (typeof msg === 'string') {
        msg = msg + '[[:' + key + ':]]'
      } else {
        const el = msg[msg.length - 1]
        el.children = el.children + '[[:' + key + ':]]'
      }
      return msg
    })
    document.body.classList.add('zslt-weblate-active')
  }
})

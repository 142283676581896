import revive_payload_client_4sVQNw7RlN from "/builds/zaslat/zaslat-b2x/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/zaslat/zaslat-b2x/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/zaslat/zaslat-b2x/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/zaslat/zaslat-b2x/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/zaslat/zaslat-b2x/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/zaslat/zaslat-b2x/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/zaslat/zaslat-b2x/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/zaslat/zaslat-b2x/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/zaslat/zaslat-b2x/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/zaslat/zaslat-b2x/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_OVoKJro5pc from "/builds/zaslat/zaslat-b2x/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_YeYU6tgo5H from "/builds/zaslat/zaslat-b2x/node_modules/@davestewart/nuxt-scrollbar/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/builds/zaslat/zaslat-b2x/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/builds/zaslat/zaslat-b2x/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_AUP22rrBAc from "/builds/zaslat/zaslat-b2x/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import BindOncePlugin_G7OaN7dVFl from "/builds/zaslat/zaslat-b2x/plugins/BindOncePlugin.ts";
import api_GFfDXud5Cr from "/builds/zaslat/zaslat-b2x/plugins/api.ts";
import font_awesome_4Kz3WSwqBj from "/builds/zaslat/zaslat-b2x/plugins/font-awesome.ts";
import notion_h8FQRIYf4m from "/builds/zaslat/zaslat-b2x/plugins/notion.ts";
import number_input_fix_hmIp1PZA9F from "/builds/zaslat/zaslat-b2x/plugins/number-input-fix.ts";
import recaptcha_85gNSCNFUU from "/builds/zaslat/zaslat-b2x/plugins/recaptcha.ts";
import router_WMnTGednOQ from "/builds/zaslat/zaslat-b2x/plugins/router.ts";
import sentry_3AyO8nEfhE from "/builds/zaslat/zaslat-b2x/plugins/sentry.ts";
import vue_final_modal_pML93k5qcp from "/builds/zaslat/zaslat-b2x/plugins/vue-final-modal.ts";
import vue_safe_html_fop7ngeYj1 from "/builds/zaslat/zaslat-b2x/plugins/vue-safe-html.ts";
import vue3_toastify_client_GgudbfYtjc from "/builds/zaslat/zaslat-b2x/plugins/vue3-toastify.client.ts";
import weblate_i18n_finder_nHnV3mQqaj from "/builds/zaslat/zaslat-b2x/plugins/weblate-i18n-finder.ts";
import yup_OM8D6DMeq7 from "/builds/zaslat/zaslat-b2x/plugins/yup.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_OVoKJro5pc,
  plugin_YeYU6tgo5H,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  plugin_AUP22rrBAc,
  BindOncePlugin_G7OaN7dVFl,
  api_GFfDXud5Cr,
  font_awesome_4Kz3WSwqBj,
  notion_h8FQRIYf4m,
  number_input_fix_hmIp1PZA9F,
  recaptcha_85gNSCNFUU,
  router_WMnTGednOQ,
  sentry_3AyO8nEfhE,
  vue_final_modal_pML93k5qcp,
  vue_safe_html_fop7ngeYj1,
  vue3_toastify_client_GgudbfYtjc,
  weblate_i18n_finder_nHnV3mQqaj,
  yup_OM8D6DMeq7
]
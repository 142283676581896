export type QuoteCarrier = Pick<QuoteResponse, 'carrier' | 'serviceName' | 'attributes'>

const fullNameCarriers = ['ups', 'fedex', 'zasilkovna', 'balikovna']

export function formatCarrierName(quote: QuoteCarrier) {
  const { $i18n } = useNuxtApp()

  if (quote.carrier.code === 'ppl' && quote.attributes.deliveryBranchRequired) {
    return quote.carrier.name + ' - ' + $i18n.t('generic.delivery_parcel_point')
  }

  if (quote.carrier.code === 'liftago') {
    return 'Liftago Network'
  }

  if (fullNameCarriers.includes(quote.carrier.code)) {
    return `${quote.carrier.name} ${quote.serviceName}`
  }

  return quote.carrier.name
}

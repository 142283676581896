import { config, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Light
import { faAddressBook } from '@zaslat/pro-light-svg-icons/faAddressBook'
import { faAddressCard } from '@zaslat/pro-light-svg-icons/faAddressCard'
import { faArrowRightFromBracket } from '@zaslat/pro-light-svg-icons/faArrowRightFromBracket'
import { faBoxesStacked } from '@zaslat/pro-light-svg-icons/faBoxesStacked'
import { faCalendar } from '@zaslat/pro-light-svg-icons/faCalendar'
import { faCartShopping } from '@zaslat/pro-light-svg-icons/faCartShopping'
import { faChevronDown } from '@zaslat/pro-light-svg-icons/faChevronDown'
import { faChevronUp } from '@zaslat/pro-light-svg-icons/faChevronUp'
import { faChevronRight } from '@zaslat/pro-light-svg-icons/faChevronRight'
import { faChevronLeft } from '@zaslat/pro-light-svg-icons/faChevronLeft'
import { faCircleEuro } from '@zaslat/pro-light-svg-icons/faCircleEuro'
import { faCircleExclamation } from '@zaslat/pro-light-svg-icons/faCircleExclamation'
import { faCircleInfo } from '@zaslat/pro-light-svg-icons/faCircleInfo'
import { faCirclePlus } from '@zaslat/pro-light-svg-icons/faCirclePlus'
import { faCircleXmark } from '@zaslat/pro-light-svg-icons/faCircleXmark'
import { faCloudUpload } from '@zaslat/pro-light-svg-icons/faCloudUpload'
import { faCode } from '@zaslat/pro-light-svg-icons/faCode'
import { faCopy } from '@zaslat/pro-light-svg-icons/faCopy'
import { faCube } from '@zaslat/pro-light-svg-icons/faCube'
import { faEarthAmericas } from '@zaslat/pro-light-svg-icons/faEarthAmericas'
import { faEnvelope } from '@zaslat/pro-light-svg-icons/faEnvelope'
import { faEye } from '@zaslat/pro-light-svg-icons/faEye'
import { faEyeSlash } from '@zaslat/pro-light-svg-icons/faEyeSlash'
import { faFilter } from '@zaslat/pro-light-svg-icons/faFilter'
import { faGear } from '@zaslat/pro-light-svg-icons/faGear'
import { faGraduationCap } from '@zaslat/pro-light-svg-icons/faGraduationCap'
import { faGrid2 } from '@zaslat/pro-light-svg-icons/faGrid2'
import { faLightbulb } from '@zaslat/pro-light-svg-icons/faLightbulb'
import { faMagnifyingGlass } from '@zaslat/pro-light-svg-icons/faMagnifyingGlass'
import { faMapMarkerAlt } from '@zaslat/pro-light-svg-icons/faMapMarkerAlt'
import { faNewspaper } from '@zaslat/pro-light-svg-icons/faNewspaper'
import { faPenToSquare } from '@zaslat/pro-light-svg-icons/faPenToSquare'
import { faQuestionCircle } from '@zaslat/pro-light-svg-icons/faQuestionCircle'
import { faSpinnerThird } from '@zaslat/pro-light-svg-icons/faSpinnerThird'
import { faStar } from '@zaslat/pro-light-svg-icons/faStar'
import { faTrashCan } from '@zaslat/pro-light-svg-icons/faTrashCan'
import { faTriangleExclamation } from '@zaslat/pro-light-svg-icons/faTriangleExclamation'
import { faUser } from '@zaslat/pro-light-svg-icons/faUser'
import { faUserPlus } from '@zaslat/pro-light-svg-icons/faUserPlus'
import { faXmark } from '@zaslat/pro-light-svg-icons/faXmark'
import { faTrash } from '@zaslat/pro-light-svg-icons/faTrash'
import { faBars } from '@zaslat/pro-light-svg-icons/faBars'
import { faSunBright } from '@zaslat/pro-light-svg-icons/faSunBright'
import { faMoon } from '@zaslat/pro-light-svg-icons/faMoon'
import { faDesktop } from '@zaslat/pro-light-svg-icons/faDesktop'
import { faThumbtack } from '@zaslat/pro-light-svg-icons/faThumbtack'
import { faArrowsRotate } from '@zaslat/pro-light-svg-icons/faArrowsRotate'

// Solid
import { faAddressBook as fasFaAddressBook } from '@zaslat/pro-solid-svg-icons/faAddressBook'
import { faArrowsRotate as fasFaArrowsRotate } from '@zaslat/pro-solid-svg-icons/faArrowsRotate'
import { faArrowUpRightFromSquare as fasArrowUpRightFromSquare } from '@zaslat/pro-solid-svg-icons/faArrowUpRightFromSquare'
import { faBriefcase as fasfaBriefcase } from '@zaslat/pro-solid-svg-icons/faBriefcase'
import { faCalendar as fasFaCalendar } from '@zaslat/pro-solid-svg-icons/faCalendar'
import { faCartShopping as fasFaCartShopping } from '@zaslat/pro-solid-svg-icons/faCartShopping'
import { faCheck as fasFaCheck } from '@zaslat/pro-solid-svg-icons/faCheck'
import { faChevronRight as fasFaChevronRight } from '@zaslat/pro-solid-svg-icons/faChevronRight'
import { faCircleCheck as fasFaCircleCheck } from '@zaslat/pro-solid-svg-icons/faCircleCheck'
import { faCircleEuro as fasFaCircleEuro } from '@zaslat/pro-solid-svg-icons/faCircleEuro'
import { faCircleInfo as fasCircleInfo } from '@zaslat/pro-solid-svg-icons/faCircleInfo'
import { faCircleStar } from '@zaslat/pro-light-svg-icons/faCircleStar'
import { faCube as fasFaCube } from '@zaslat/pro-solid-svg-icons/faCube'
import { faDollarCircle } from '@zaslat/pro-solid-svg-icons/faDollarCircle'
import { faEllipsis as fasFaEllipsis } from '@zaslat/pro-solid-svg-icons/faEllipsis'
import { faEnvelope as fasfaEnvelope } from '@zaslat/pro-solid-svg-icons/faEnvelope'
import { faExclamationTriangle as fasFaExclamationTriangle } from '@zaslat/pro-solid-svg-icons/faExclamationTriangle'
import { faFile as fasFaFile } from '@zaslat/pro-solid-svg-icons/faFile'
import { faFileExcel as fasFaFileExcel } from '@zaslat/pro-solid-svg-icons/faFileExcel'
import { faFileSlash as fasFaFileSlash } from '@zaslat/pro-solid-svg-icons/faFileSlash'
import { faGrid2 as fasGrid2 } from '@zaslat/pro-solid-svg-icons/faGrid2'
import { faHourglass as fasfFaHourglass } from '@zaslat/pro-solid-svg-icons/faHourglass'
import { faPlus as fasFaPlus } from '@zaslat/pro-solid-svg-icons/faPlus'
import { faRulerTriangle } from '@zaslat/pro-light-svg-icons/faRulerTriangle'
import { faSort as fasFaSort } from '@zaslat/pro-solid-svg-icons/faSort'
import { faSortDown as fasFaSortDown } from '@zaslat/pro-solid-svg-icons/faSortDown'
import { faSortUp as fasFaSortUp } from '@zaslat/pro-solid-svg-icons/faSortUp'
import { faStar as fasFaStar } from '@zaslat/pro-solid-svg-icons/faStar'
import { faStarHalfStroke as fasFaStarHalfStroke } from '@zaslat/pro-solid-svg-icons/faStarHalfStroke'
import { faStars as fasfFaStars } from '@zaslat/pro-solid-svg-icons/faStars'
import { faTriangleExclamation as fasFaTriangleExclamation } from '@zaslat/pro-solid-svg-icons/faTriangleExclamation'
import { faUser as fasfaUser } from '@zaslat/pro-solid-svg-icons/faUser'
import { faGear as fasfaGear } from '@zaslat/pro-solid-svg-icons/faGear'
import { faSlidersUp as fasSlidersUp } from '@zaslat/pro-solid-svg-icons/faSlidersUp'
import { faThumbtack as fasThumbtack } from '@zaslat/pro-solid-svg-icons/faThumbtack'

config.autoAddCss = false

library.add(
  faTrash,
  faAddressBook,
  faAddressCard,
  faArrowRightFromBracket,
  faBoxesStacked,
  faCalendar,
  faCartShopping,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faCircleEuro,
  faCircleExclamation,
  faCircleInfo,
  faCirclePlus,
  faCircleStar,
  faCircleXmark,
  faCloudUpload,
  faCode,
  faCopy,
  faCube,
  faDollarCircle,
  faEarthAmericas,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFilter,
  faGear,
  fasfaGear,
  faGraduationCap,
  faGrid2,
  faLightbulb,
  faMagnifyingGlass,
  faMapMarkerAlt,
  faNewspaper,
  faPenToSquare,
  faQuestionCircle,
  faRulerTriangle,
  fasArrowUpRightFromSquare,
  fasCircleInfo,
  fasFaAddressBook,
  fasFaArrowsRotate,
  fasfaBriefcase,
  fasFaCalendar,
  fasFaCartShopping,
  fasFaCheck,
  fasFaChevronRight,
  fasFaCircleCheck,
  fasFaCircleEuro,
  fasFaCube,
  fasFaEllipsis,
  fasfaEnvelope,
  fasFaExclamationTriangle,
  fasFaFile,
  fasFaFileExcel,
  fasFaFileSlash,
  fasFaPlus,
  fasFaSort,
  fasFaSortDown,
  fasFaSortUp,
  fasFaStar,
  fasFaStarHalfStroke,
  fasFaTriangleExclamation,
  fasfaUser,
  fasfFaHourglass,
  fasfFaStars,
  fasGrid2,
  faSpinnerThird,
  faStar,
  faTrashCan,
  faTriangleExclamation,
  faUser,
  faUserPlus,
  faXmark,
  faBars,
  faSunBright,
  faMoon,
  faDesktop,
  fasSlidersUp,
  faThumbtack,
  fasThumbtack,
  faArrowsRotate,
  faThumbtack,
  fasThumbtack,
)

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon)
})

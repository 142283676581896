import axios from 'axios'
import type { AxiosError, InternalAxiosRequestConfig } from 'axios'
import { useAuthStore } from '~/stores/auth-store'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  const resolveApiUrl = (): string => {
    let url = config.public.apiUrlBase
    if (config.public.appEnv === 'production' && import.meta.client && window.location.origin.match(/app-test\.zaslat/)) {
      url = 'https://test.zaslat.cz'
    }
    return url
  }

  const baseApiUrl = resolveApiUrl()

  const api = axios.create({
    baseURL: baseApiUrl + '/api/v2',
  })

  const apiV1 = axios.create({
    baseURL: baseApiUrl + '/api/v1',
  })

  const apiPublic = axios.create()

  const authInterceptor = (c: InternalAxiosRequestConfig) => {
    if (c.headers) {
      c.headers['x-apikey'] = useAuthStore().token
      c.headers['Accept-Language'] = config.public.territory.replace('cz', 'cs')
    }

    return c
  }

  const responseAuthInterceptor = (response: AxiosError) => {
    if (response.request && response.response && response.response.status === 401) {
      const shouldLogout = response.request.responseURL.includes(baseApiUrl)
        && !JSON.stringify(response.response.data).match(/Captcha validation failed/i)
      if (shouldLogout) {
        useAuthStore().logout()
        nuxtApp.$router.replace('/login')
      }
    }
    return Promise.reject(response)
  }

  api.interceptors.request.use(authInterceptor)
  apiV1.interceptors.request.use(authInterceptor)

  api.interceptors.response.use(undefined, responseAuthInterceptor)
  apiV1.interceptors.response.use(undefined, responseAuthInterceptor)

  return {
    provide: {
      api,
      apiV1,
      apiPublic,
      baseApiUrl,
    },
  }
})

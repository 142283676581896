<template>
  <VueFinalModal
    class="flex items-center justify-center"
    content-class="mx-4 flex w-full max-w-md flex-col space-y-2 rounded-lg border bg-white p-4 dark:border-gray-600 dark:bg-gray-700"
    :focus-trap="false"
    :hide-overlay="false"
    :esc-to-close="true"
  >
    <BaseTitle
      size="h2"
      class="text-lg"
      :title="title"
    />
    <div class="py-2 text-sm">
      <slot />
    </div>
    <div class="flex items-center justify-center space-x-4">
      <BaseButton
        size="sm"
        @click="emit('close')"
      >
        {{ label }}
      </BaseButton>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'

interface Props {
  title: string
  buttonLabel?: string
}

const props = withDefaults(defineProps<Props>(), {
  buttonLabel: undefined,
})

const { t } = useI18n()
const label = props.buttonLabel || t('generic.acknowledge')

const emit = defineEmits<{
  (e: 'close'): void
}>()
</script>

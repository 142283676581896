<template>
  <LazyClientOnly>
    <Popper
      v-if="popperInited"
      ref="popperRef"
      hover
      arrow
      :show="show"
      :placement="props.placement"
    >
      <div class="inline-block cursor-pointer">
        <slot />
      </div>

      <template #content>
        <div
          role="tooltip"
          class="rounded-lg text-sm font-light"
          :class="{
            'space-y-2 p-3': !stylelessContent,
          }"
        >
          <h3
            v-if="props.label"
            class="text-sm font-semibold"
          >
            {{ props.label }}
          </h3>

          <slot name="content" />

          <LazyBaseProgress
            v-if="props.progress"
            :progress="props.progress"
            :color="props.color"
          />
        </div>
      </template>
    </Popper>
    <div
      v-else
      class="inline-block cursor-pointer"
      @mouseover="initPopper"
    >
      <slot />
    </div>
  </LazyClientOnly>
</template>

<script lang="ts" setup>
import Popper from 'vue3-popper'

export type PopoverPlacement = 'top' | 'bottom' | 'right' | 'left'

interface Props {
  placement?: PopoverPlacement
  label?: string
  progress?: number
  color?: string
  show?: boolean
  stylelessContent?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  placement: 'top',
  color: undefined,
  label: undefined,
  progress: undefined,
  show: undefined,
  stylelessContent: false,
})

const popperRef = ref()
defineExpose({
  popperRef,
})

const popperInited = ref(false)

const initPopper = () => {
  if (!popperInited.value) {
    popperInited.value = true
  }
}
</script>

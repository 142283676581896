<template>
  <VueFinalModal
    :class="computedClasses"
    :content-transition="sidePanel ? 'vfm-slide-right' : undefined"
    :content-class="contentClasses"
    :focus-trap="false"
    :click-to-close="clickOutside ? false : clickToClose"
    :esc-to-close="escToClose"
    @closed="$emit('closed')"
    @click-outside="clickOutside ? clickOutsideFn() : false"
    @input="onContentChanged"
    @change="onContentChanged"
  >
    <slot />
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import { clsx } from 'clsx/lite'

type modalSize = 'sm' | 'md' | 'lg'

const emit = defineEmits(['close', 'closed'])

const props = withDefaults(
  defineProps<{
    clickToClose?: boolean
    clickOutside?: boolean
    escToClose?: boolean
    size?: modalSize
    sidePanel?: boolean
  }>(),
  {
    clickToClose: true,
    clickOutside: true,
    escToClose: true,
    size: 'md',
  },
)

const contentChanged = ref<boolean>(false)

const sizes = {
  sm: 'max-w-sm',
  md: 'max-w-lg',
  lg: 'max-w-3xl',
  xl: 'max-w-5xl',
}

const computedSize = computed<string>(() => {
  return sizes[props.size]
})
const computedClasses = computed<string>(() =>
  clsx('flex', props.sidePanel ? 'justify-end' : 'items-center justify-center p-4'),
)

const contentClasses = computed<string>(() =>
  clsx(
    computedSize.value,
    'pointer-events-auto relative flex max-h-full w-full flex-col overflow-hidden border bg-white bg-clip-padding outline-0 dark:border-gray-600 dark:bg-gray-800',
    !props.sidePanel && 'rounded-lg',
  ),
)

const clickOutsideFn = async () => {
  emit('close', contentChanged.value)
}

const onContentChanged = () => {
  contentChanged.value = true
}
</script>

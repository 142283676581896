import { defineStore } from 'pinia'
import { useAuthStore } from '~/stores/auth-store'

export const useBaseStore = defineStore('base', {
  state: () => ({
    language: null as string | null,
    countries: [] as CountryAllResponseItem[],
    isLoading: false,
    recaptchaInstances: 0,
  }),
  getters: {
    countriesMap: (state) => {
      const { $i18n } = useNuxtApp()
      return state.countries.reduce(
        (acc, item) => {
          acc[item.code] = $i18n.t(`countries.${item.code.toLowerCase()}`)
          return acc
        },
        {} as { [k: string]: string },
      )
    },
    countriesMapImport: (state) => {
      const { $i18n } = useNuxtApp()
      return state.countries.reduce(
        (acc, item) => {
          if (item.import === 1) {
            acc[item.code] = $i18n.t(`countries.${item.code.toLowerCase()}`)
          }
          return acc
        },
        {} as { [k: string]: string },
      )
    },
    countriesMapExport: (state) => {
      const { $i18n } = useNuxtApp()
      return state.countries.reduce(
        (acc, item) => {
          if (item.export === 1) {
            acc[item.code] = $i18n.t(`countries.${item.code.toLowerCase()}`)
          }
          return acc
        },
        {} as { [k: string]: string },
      )
    },
    getCountryById: (state) => {
      return (countryId: number) => {
        return state.countries.find((item) => item.id === countryId)
      }
    },
    getCountryByCode: (state) => {
      return (countryCode: string) => {
        return state.countries.find((item) => item.code === countryCode)
      }
    },
  },
  actions: {
    async fetchCountries() {
      const { $apiV1 } = useNuxtApp()
      const { data } = await $apiV1.get('/country/all')
      this.countries = data.data
      this.countries.sort((a, b) => {
        return a.priority > b.priority ? -1 : 1
      })
    },
    async fetchLanguage() {
      if (!useAuthStore().isAuthenticated) {
        return
      }

      const { $api } = useNuxtApp()
      const { data } = await $api.get('/user/settings/list-common')
      this.language = data.preferredLanguage
    },
  },
  persist: {
    paths: ['language'],
  },
})

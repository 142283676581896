import type { RouteLocationNormalized } from 'vue-router'

export default defineNuxtPlugin(({ $router }) => {
  $router.options.scrollBehavior = (to: RouteLocationNormalized, savedPosition: unknown) => {
    if (to.hash) {
      return {
        el: to.hash,
        top: 30,
        behavior: 'smooth',
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

<template>
  <VueFinalModal
    class="flex items-center justify-center"
    content-class="mx-4 flex w-full max-w-md flex-col space-y-2 rounded-lg border bg-white p-4 dark:border-gray-600 dark:bg-gray-700"
    :focus-trap="false"
    :hide-overlay="false"
    :click-to-close="false"
    :esc-to-close="false"
  >
    <BaseTitle
      v-if="title"
      size="h2"
      class="text-lg"
      :title="title"
    />
    <div class="py-2 text-sm">
      <slot />
    </div>
    <div class="flex items-center justify-center gap-4">
      <BaseButton
        size="sm"
        color="alternative"
        @click="emit('cancel')"
      >
        {{ noLabel }}
      </BaseButton>
      <BaseButton
        size="sm"
        :color="deleteButton ? 'red' : undefined"
        @click="emit('confirm')"
      >
        {{ yesLabel }}
      </BaseButton>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core'
import { VueFinalModal } from 'vue-final-modal'

interface Props {
  title: string
  yesButtonLabel?: string
  noButtonLabel?: string
  deleteButton?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  yesButtonLabel: undefined,
  noButtonLabel: undefined,
})

const { t } = useI18n()
const yesLabel = props.yesButtonLabel || t('generic.confirm')
const noLabel = props.noButtonLabel || t('generic.cancel')

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'confirm'): void
  (e: 'cancel'): void
}>()

onKeyStroke('Enter', () => {
  emit('confirm')
})

onKeyStroke('Escape', () => {
  emit('cancel')
})
</script>

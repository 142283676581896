import type { Constructor } from 'vue-final-modal'
import { useModal, useModalSlot } from 'vue-final-modal'
import type { VNodeProps } from 'nuxt/dist/app/compat/capi'
import { vfm } from '@/plugins/vue-final-modal'
import ModalAlert from '@/components/modal/Alert.vue'
import ModalConfirm from '@/components/modal/Confirm.vue'
import ModalGeneric from '@/components/modal/Generic.vue'

export async function openAlert(title: string, text: string, buttonLabel?: string): Promise<boolean> {
  return new Promise((resolve) => {
    const modalInstance = useModal({
      context: vfm,
      component: ModalAlert,
      attrs: {
        title,
        buttonLabel,
        onClose() {
          modalInstance.close()
          resolve(true)
        },
        onClosed() {
          modalInstance.destroy()
        },
      },
      slots: { default: text },
    })
    modalInstance.open()
  })
}

export async function openConfirm(
  title: string,
  text: string,
  yesButtonLabel?: string,
  noButtonLabel?: string,
  deleteButton?: boolean,
): Promise<boolean> {
  return new Promise((resolve) => {
    const modalInstance = useModal({
      context: vfm,
      component: ModalConfirm,
      attrs: {
        title,
        yesButtonLabel,
        noButtonLabel,
        deleteButton,
        onCancel() {
          modalInstance.close()
          resolve(false)
        },
        onConfirm() {
          modalInstance.close()
          resolve(true)
        },
        onClose() {
          resolve(false)
          modalInstance.close()
        },
        onClosed() {
          modalInstance.destroy()
        },
      },
      slots: { default: text },
    })
    modalInstance.open()
  })
}

export function openModal(
  component: Constructor,
  attrs?: VNodeProps & Record<string, unknown>,
  modalAttrs?: Parameters<typeof useModal>[0]['attrs'],
) {
  const modalInstance = useModal({
    context: vfm,
    component: ModalGeneric,
    attrs: {
      async onClose(e: boolean) {
        if (e) {
          if (await clickOutside()) {
            modalInstance.close()
          }
        } else {
          modalInstance.close()
        }
      },
      onClosed() {
        modalInstance.destroy()
      },
      ...modalAttrs,
    },
    slots: {
      default: useModalSlot({
        component,
        attrs: attrs || {
          onClose() {
            modalInstance.close()
          },
        },
      }),
    },
  })
  modalInstance.open()
  return modalInstance
}

const clickOutside = () => {
  const { $i18n } = useNuxtApp()

  return openConfirm(
    $i18n.t('generic.unsaved_changes'),
    $i18n.t('generic.unsaved_changes_text'),
    $i18n.t('generic.actions.close'),
    $i18n.t('components.shipment.go_back'),
    true,
  )
}

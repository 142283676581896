<template>
  <div />
</template>

<script lang="ts" setup>
import { LazyWelcomeVideoModal } from '#components'
import { useAuthStore } from '~/stores/auth-store'

const welcomeVideoCookie = useCookie('welcome-video')
const isAuthenticated = computed(() => useAuthStore().isAuthenticated)

const emit = defineEmits<{
  (e: 'startGuide'): void
}>()

onMounted(() => {
  if (!welcomeVideoCookie.value && isAuthenticated.value && allowedPages.value) {
    const modal = openModal(
      LazyWelcomeVideoModal,
      {
        onClose() {
          modal.close()
        },
        onStartGuide() {
          modal.close()
          emit('startGuide')
        },
      },
      {
        size: 'lg',
      },
    )
  }
})
</script>

<template>
  <Component
    :is="size"
    v-if="loading"
    v-bind="$attrs"
    class="w-1/2 animate-pulse bg-gray-300"
  >
    &nbsp;
  </Component>
  <template v-else-if="video">
    <div
      class="flex items-baseline gap-2"
      v-bind="$attrs"
    >
      <Component :is="size">
        {{ title }}
      </Component>

      <button
        class="cursor-pointer text-xs text-primary-600 hover:underline dark:text-primary-500"
        @click="openVideo"
      >
        {{ $t('generic.video_tutorial') }}
      </button>
    </div>
  </template>
  <Component
    :is="size"
    v-bind="$attrs"
    v-else
  >
    {{ title }}
  </Component>
</template>

<script setup lang="ts">
import { LazyTutorialVideoModal } from '#components'

type headingSize = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

const props = withDefaults(
  defineProps<{
    title: string
    video?: string
    size?: headingSize
    loading?: boolean
  }>(),
  {
    video: undefined,
    size: 'h1',
  },
)

const openVideo = () => {
  const modal = openModal(
    LazyTutorialVideoModal,
    {
      title: props.title,
      video: props.video,
      onClose() {
        modal.close()
      },
      onDone() {
        modal.close()
      },
    },
    {
      size: 'lg',
    },
  )
}
</script>

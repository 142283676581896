<template>
  <BaseLink
    :to="linkUrl"
    color="none"
    :underline="false"
  >
    <img
      :src="imageUrl"
      class="h-8 w-auto md:block"
      :class="!mobileVersionDisabled && 'hidden'"
      alt="Logo"
      loading="eager"
    >

    <img
      :src="imageUrlSmall"
      class="h-8 w-auto md:hidden"
      :class="mobileVersionDisabled && 'hidden'"
      alt="Logo"
      loading="eager"
    >
  </BaseLink>
</template>

<script setup lang="ts">
import { useUserStore } from '~/stores/user-store'

interface Props {
  mobileVersionDisabled?: boolean
  white?: boolean
  to?: string
}

const props = withDefaults(defineProps<Props>(), {
  mobileVersionDisabled: false,
  white: false,
  to: undefined,
})

const userProfile = useUserStore().profile

const config = useRuntimeConfig()

const colorMode = useColorMode()

const linkUrl = computed<string>(() => {
  if (props.to) {
    return props.to
  }

  return userProfile?.defaultPageSm === 'shipments' ? '/shipments' : '/'
})

const light = computed<boolean>(() => props.white || colorMode.value !== 'light')

const imageUrl = computed<string>(() => `/images/${config.public.brand}-logo${light.value ? '-white' : ''}.svg`)

const imageUrlSmall = computed<string>(() => `/images/${config.public.brand}-logo-sm${light.value ? '-white' : ''}.svg`)
</script>

import { defineStore } from 'pinia'
import { isAxiosError } from 'axios'
import { useUserStore } from '~/stores/user-store'
import { useBaseStore } from '~/stores/base-store'
import { useOmnicartStore } from '~/stores/omnicart-store'

const getAuthCookieDomain = () => {
  const hostnameParts = location.hostname.split('.')
  hostnameParts.shift()
  return '.' + hostnameParts.join('.')
}
function getAuthCookieName() {
  const appEnv = useRuntimeConfig().public.appEnv
  const authKeySuffix = appEnv === 'production' ? '' : '_' + appEnv
  return 'zslt_auth_key' + authKeySuffix
}
const setDomainAuthCookie = (apiKey: string) => {
  const authCookie = useCookie(getAuthCookieName(), {
    maxAge: 1704085200,
    domain: getAuthCookieDomain(),
  })
  authCookie.value = apiKey
}
const unsetDomainAuthCookie = () => {
  const authCookie = useCookie(getAuthCookieName(), {
    maxAge: -1,
    domain: getAuthCookieDomain(),
  })
  authCookie.value = null
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: null as string | null,
    securityToken: '' as string,
    isRegisterQuick: false as boolean,
    fromRedirect: undefined as string | undefined,
  }),
  getters: {
    isAuthenticated: (state) => state.token !== null,
  },
  actions: {
    async handleQueryReferer() {
      const { query } = useRoute()

      if (query.from) {
        useAuthStore().fromRedirect = query.from as string | undefined
      }

      // get rid of get parameters
      return useRouter().replace({ query: {} })
    },
    async handleRedirect() {
      const { fromRedirect } = this

      if (fromRedirect) {
        useAuthStore().fromRedirect = undefined

        if (fromRedirect.match(/^https?:.*/)) {
          window.location.replace(fromRedirect)
          return false
        }

        await navigateTo(fromRedirect)
        return true
      }

      return false
    },
    async login(email: string, password: string) {
      const { $api } = useNuxtApp()
      const { data } = await $api.post('/auth/login', {
        login: email,
        password,
      })

      if (!data?.apiKey) {
        const { $i18n } = useNuxtApp()
        throw new Error($i18n.t('stores.address_store.api_error'))
      }

      this.token = data.apiKey
      setDomainAuthCookie(data.apiKey)
      await useUserStore().fetchProfile()
    },
    async loginWithApiKey(apiKey: string) {
      this.token = apiKey
      setDomainAuthCookie(apiKey)
      await useUserStore().fetchProfile()
      await useBaseStore().fetchLanguage()
    },
    async logout() {
      useUserStore().$reset()
      useOmnicartStore().$reset()
      this.$reset()
      unsetDomainAuthCookie()
    },
    async lostPassword(email: string) {
      const { $api } = useNuxtApp()
      await $api.post('/auth/sm-reset', {
        email,
      })
    },
    async resetPassword(token: string, password: string, passwordRepeat: string) {
      const { $api } = useNuxtApp()
      await $api.post('/auth/change-password', {
        token,
        password,
        passwordRepeat,
      })

      this.securityToken = ''
      this.isRegisterQuick = false
    },
    async privateRegistrationNewsletter(email: string) {
      const { $api } = useNuxtApp()
      await $api.post('/auth/reminder', {
        email,
      })
    },
    async register(email: string, password: string, passwordRepeat: string, type: 'registerCompany' | 'registerCommon') {
      const { $api } = useNuxtApp()
      const { data } = await $api.post('/auth/register', {
        email,
        password,
        passwordRepeat,
        countryCode: null,
        currency: null,
        type,
      })

      const { apiKey } = data

      if (!apiKey) {
        const { $i18n } = useNuxtApp()
        throw new Error($i18n.t('stores.address_store.api_error'))
      }

      this.token = apiKey
      setDomainAuthCookie(apiKey)
      await useUserStore().fetchProfile()
    },
    async registerQuick(token: string) {
      const { $api, $i18n } = useNuxtApp()
      useAuthStore().logout()

      // Timeout is needed to persist immediately after reset... dunno why. Is there a better way?
      setTimeout(() => {
        this.isRegisterQuick = true
      }, 300)

      try {
        const { data } = await $api.post('/auth/quick-register', { data: token })
        const { apiKey, securityToken } = data
        this.securityToken = securityToken
        this.isRegisterQuick = true
        useAuthStore().loginWithApiKey(apiKey)
      } catch (e: unknown) {
        this.isRegisterQuick = false
        if (isAxiosError(e)) {
          await openAlert($i18n.t('pages.register.error'), e.message)
        } else {
          await openAlert($i18n.t('pages.register.error'), $i18n.t('generic.unknown_error'))
        }
      } finally {
        navigateTo('/')
      }
    },
    async registerStep2(payload: {
      registrationType: string
      firstname: string
      surname: string
      countryCode: string
      street: string
      city: string
      zip: string
      phone: string
      email: string
      tin: string
      vatin: string
      company: string
    }) {
      const { $api } = useNuxtApp()
      await $api.post('/auth/register-extended', payload)
      await useUserStore().fetchProfile()
    },
  },
  persist: true,
})

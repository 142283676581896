<template>
  <button
    type="button"
    :class="buttonClass"
    :disabled="disabled || skeleton"
    v-bind="$attrs"
  >
    <div
      v-if="loading"
      class="animate-spin"
    >
      <BaseIcon icon="fal fa-spinner-third" />
    </div>
    <span
      v-if="!loading && icon"
      :class="iconClass"
    >
      <BaseIcon :icon="icon" />
    </span>
    <slot />
  </button>
</template>

<script lang="ts" setup>
import { clsx } from 'clsx/lite'

type ButtonSize = (typeof buttonSize)[number]
type ButtonVariant = (typeof buttonVariants)[number]

interface Props {
  color?: ButtonVariant
  size?: ButtonSize
  disabled?: boolean
  loading?: boolean
  skeleton?: boolean
  icon?: string
  iconClass?: string
  fullSize?: boolean
  rounded?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  color: 'default',
  size: 'md',
  icon: '',
  iconClass: '',
})

const sizes = {
  xs: clsx('px-3 py-2 text-xs'),
  sm: clsx('px-3 py-2 text-sm'),
  md: clsx('px-5 py-2.5 text-sm'),
  lg: clsx('px-5 py-3 text-base'),
  xl: clsx('px-6 py-3.5 text-base'),
}

const themes: Record<ButtonVariant, string> = {
  default: clsx(
    'border-orange-500 bg-orange-500 text-white focus:ring-orange-400 enabled:hover:border-orange-600 enabled:hover:bg-orange-600 dark:border-orange-500 dark:text-white dark:focus:ring-orange-900',
  ),
  purple: clsx(
    'border-primary-700 bg-primary-700 text-white focus:ring-primary-300 enabled:hover:border-primary-800 enabled:hover:bg-primary-800 dark:border-primary-600 dark:bg-primary-600 dark:text-white dark:focus:ring-primary-800 dark:enabled:hover:bg-primary-700',
  ),
  alternative: clsx(
    'border-gray-200 bg-white text-gray-900 focus:ring-gray-200 enabled:hover:bg-gray-100 enabled:hover:text-primary-700 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:focus:ring-gray-700 dark:enabled:hover:bg-gray-700 dark:enabled:hover:text-white',
  ),
  dark: clsx(
    'border-gray-800 bg-gray-800 text-white focus:ring-gray-300 enabled:hover:border-gray-900 enabled:hover:bg-gray-900  dark:border-gray-800 dark:bg-gray-800 dark:text-white dark:focus:ring-gray-700 dark:enabled:hover:bg-gray-700',
  ),
  green: clsx(
    'border-green-700 bg-green-700 text-white focus:ring-green-300 enabled:hover:border-green-800 enabled:hover:bg-green-800 dark:border-green-600 dark:bg-green-600 dark:text-white dark:focus:ring-green-800 dark:enabled:hover:border-green-700 dark:enabled:hover:bg-green-700',
  ),
  red: clsx(
    'border-red-700 bg-red-700 text-white focus:ring-red-300 enabled:hover:border-red-800 enabled:hover:bg-red-800 dark:border-red-600 dark:bg-red-600 dark:text-white dark:focus:ring-red-900 dark:enabled:hover:border-red-700 dark:enabled:hover:bg-red-700',
  ),
}

const buttonClass = computed<string>(() =>
  clsx(
    'inline-flex items-center justify-center gap-3 rounded-full border text-center font-medium transition focus:outline-none focus:ring-4',
    props.rounded ? 'size-14 p-1 text-base' : 'h-full',
    props.fullSize && 'w-full',
    sizes[props.size],
    props.skeleton
      ? 'animate-pulse border-gray-100 bg-gray-100 text-gray-100 dark:bg-gray-900 dark:text-gray-900'
      : props.disabled
        ? 'cursor-not-allowed bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-300'
        : themes[props.color],
  ),
)
</script>

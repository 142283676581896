<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
      <ClientOnly>
        <ModalsContainer />
        <WelcomeVideo @start-guide="startGuide" />
        <LazyRegistrationQuick />
        <PopoverGuide
          v-if="activeGuide !== null"
          :guide="activeGuide"
          @end="endGuide"
        />
        <LazyPopupNews />
      </ClientOnly>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { ModalsContainer } from 'vue-final-modal'
import { useUserStore } from '~/stores/user-store'
import { useBaseStore } from '~/stores/base-store'

const { t, setLocale } = useI18n()
const config = useRuntimeConfig()
const activeGuide = ref<null | string>(null)

const clarityScript = computed<string>(() => {
  if (config.public.appEnv === 'production') {
    return `
(function(c,l,a,r,i,t,y){
  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "gz4de5i5ze");
`
  }
  return ''
})

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${t('brand.product_name')}` : t('brand.product_name')
  },
  script: [
    {
      textContent: clarityScript,
    },
  ],
})

const startGuide = () => {
  activeGuide.value = 'shipments'
}

const endGuide = () => {
  activeGuide.value = null
}

onMounted(() => {
  useBaseStore().fetchCountries()
  console.log(config.public.version)

  if (allowedPages.value) {
    useUserStore().fetchProfile()
    useBaseStore().fetchLanguage()
    const preferredLang = useBaseStore().language

    if (
      preferredLang !== null &&
      (useI18n().locales.value).find((lang) => lang.code === preferredLang)
    ) {
      setLocale(preferredLang as string)
    }
  }
})
</script>

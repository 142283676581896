// MS Clarity is a pice of shit and breaks number inputs - it allows to change the input value when scrolling the page while keeping the mouse cursor over the input (not even focused)
// This plugin disables wheel event over number input to fix the issue
export default defineNuxtPlugin(() => {
  if (document) {
    document.addEventListener('wheel', () => {
      if (document.activeElement !== null && (document.activeElement as HTMLInputElement).type === 'number') {
        ;(document.activeElement as HTMLInputElement).blur()
      }
    })
  }
})
